import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { IshareDashboardService } from 'src/app/services/ishare-dashboard.service';

@Component({
  selector: 'app-ishare-dashboard-actiongrid',
  templateUrl: './ishare-dashboard-actiongrid.component.html',
  styleUrls: ['./ishare-dashboard-actiongrid.component.scss']
})
export class IshareDashboardActiongridComponent implements OnInit, OnChanges {


  @Input() data: any[] = [];
  @Input() customerDocumentGuid: any;
  @Output() save = new EventEmitter<any>();
  public state: State = {
  };
  gridData: GridDataResult;
  constructor(private _dashboardService: IshareDashboardService) { }

  ngOnInit() {
  }

  public showData() {

  }


  public onStateChange(state: State = this.state): void {
    state = <DataStateChangeEvent>state;
    this.state = state;
    this.gridData = process(this.data || [], this.state);

  }
  ngOnChanges() {
    this.onStateChange(this.state);
  }

  public sendResponse(dataItem, field) {
    if (field == 'Sign') {
      this.customerLogAction(dataItem.customerDocumentGuid, 'ShareDocsDocViewed');
    }
    let obj = {
      customerDocumentGuid: dataItem.customerDocumentGuid,
      documentId: dataItem.documentId,
      rawData: dataItem,
      field: field
    }
    this.save.emit({ ...obj });
  }
  public sendUploadResponse(file, customerDocumentGuid, field) {
    let obj = {
      file: file,
      customerDocumentGuid: customerDocumentGuid,
      field: field
    }
    this.save.emit({ ...obj });
  }
  customerLogAction(customerDocumentGuid, type) {
    let obj = {
      type: type,
      customerDocumentGuid: customerDocumentGuid
    }
    this._dashboardService.customerLogAction(obj).subscribe(data => {

    }, err => {

    });
  }
}
