import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from "@angular/common";
@Pipe({
  name: 'iShareDateFormat'
})
export class IShareDateFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      var datePipe = new DatePipe("en-US");
      let shortDate = "dd/MM/yyyy"
      let longDate = "dd/MMM/yyyy HH:mm"
      if (args == "short") {
        value = datePipe.transform(value, shortDate);
      } else if (args == "long") {
        value = datePipe.transform(value, longDate);
      } else {
        value = "";
      }
      return value;
    }
    return "";
  }

}
