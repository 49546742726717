import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from 'src/app/app-config.service';
import { Helper } from 'src/app/models/helper';
import { VerifyPinModel } from 'src/app/models/verifyPinModel';
import { IShareLoginService } from 'src/app/services/ishare-login.service';
import { TranslateAppService } from 'src/app/services/translate-app.service';

@Component({
  selector: 'app-ishare-verify-otp',
  templateUrl: './ishare-verify-otp.component.html',
  styleUrls: ['./ishare-verify-otp.component.scss']
})
export class IshareVerifyOtpComponent implements OnInit {
  verifyOTP: FormControl = new FormControl('', Validators.compose([Validators.required, Validators.pattern('[0-9]{6}')]));
  verifyOTPObj: VerifyPinModel;
  verifyId: any;
  emailOrPhonePresent: number;
  maskedEmail: string;
  maskedphone: string;
  helperRef = Helper;
  constructor(private route: ActivatedRoute, private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private _iShareAuthService: IShareLoginService,
    private _appTranslateService: TranslateAppService) {
    this.route.queryParams.subscribe(value => {
      this.verifyId = value.q;
    });
  }

  ngOnInit(): void {
    if (this.verifyId == undefined || this.verifyId == null || this.verifyId.trim() === '') {
      setTimeout(() => {
        this.toastr.error(this._appTranslateService.getTranslattedTextFromJSONFile('VALIDATIONMESSAGES.ERRORMESSAGE1'));
        this.router.navigate(['verifyidentity'], { queryParams: { 'q': localStorage.getItem('iShare_queryString') } });
      });
    }
    if (this._iShareAuthService.getQueryString(this.verifyId) == '' || this._iShareAuthService.getQueryString(this.verifyId) == null || this._iShareAuthService.getQueryString(this.verifyId) == undefined) {
      if (this.verifyId != null || this.verifyId != undefined) {
        this._iShareAuthService.updateQueryString(this.verifyId);
      }
    }
    // if (this._iShareAuthService.getQueryString()) {
    //   if (this._iShareAuthService.getEmailOnLogin() == null || this._iShareAuthService.getPhoneNo() == null) {
    //     this.router.navigate(['verifyidentity'], { queryParams: { 'q': localStorage.getItem('iShare_queryString') } });
    //   }
    // }
    this.maskPhoneAndEmail();
  }

  maskPhoneAndEmail() {
    if (JSON.parse(localStorage.getItem('email')) != '' && JSON.parse(localStorage.getItem('email')) != null) {
      this.maskedEmail = Helper.maskEmailAddress(JSON.parse(localStorage.getItem('email')), "*");
    }
    if (JSON.parse(localStorage.getItem('phone')) != '' && JSON.parse(localStorage.getItem('phone')) != null) {
      this.maskedphone = Helper.maskPhoneNumber(JSON.parse(localStorage.getItem('phone')), "*");
    }
  }


  validateOTP() {
    this.verifyOTPObj = {
      email: JSON.parse(localStorage.getItem('email')) ? JSON.parse(localStorage.getItem('email')) : '',
      phone: JSON.parse(localStorage.getItem('phone')) ? JSON.parse(localStorage.getItem('phone')) : '',
      pin: this.verifyOTP.value,
      queryString: this.verifyId
    }
    this.spinner.show();
    this._iShareAuthService.verifyePin(this.verifyOTPObj).subscribe(data => {
      if (data.success) {
        if (this.verifyOTPObj.email !== '' && this.verifyOTPObj.email !== null) {
          this._iShareAuthService.updateEmailId(this.verifyOTPObj.email);
          this.emailOrPhonePresent = 1; //sends 1 while email ss present
        }

        if (this.verifyOTPObj.phone !== '' && this.verifyOTPObj.phone !== null) {
          this._iShareAuthService.updatephoneNo(this.verifyOTPObj.phone);
          this.emailOrPhonePresent = 0; //sends 0 while phoneno ss present
        }

        this._iShareAuthService.updateAuthToken(data.result.accessToken);
        this._iShareAuthService.updateQueryString(this.verifyId);
        this.router.navigate(['/dashboard', this.emailOrPhonePresent]);
        this.spinner.hide();
      } else {
        // this._iShareErrorService.handleError(data.statusCode)
        // this.toastr.error(data.errors[0]);
        this.spinner.hide();
      }
    }, err => {
      this.spinner.hide();
    })
  }
  backToHome() {
    localStorage.removeItem('email');
    localStorage.removeItem('phone');
    this.router.navigate(['verifyidentity'], { queryParams: { 'q': localStorage.getItem('iShare_queryString') } });
  }
}
