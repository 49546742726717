import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CanActivate } from '@angular/router/src/utils/preactivation';
import { ToastrService } from 'ngx-toastr';
import { IShareLoginService } from '../services/ishare-login.service';
import { TranslateAppService } from '../services/translate-app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(private toast: ToastrService, private router: Router, private _authService: IShareLoginService,
    private _appTranslateService: TranslateAppService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canGoToRoute(route, state.url);
  }

  canGoToRoute(routepath, redirectUrl: string): boolean {
    if (this._authService.getAuthToken()) {
      return true;
    }

    else {
      this.toast.error(this._appTranslateService.getTranslattedTextFromJSONFile('VALIDATIONMESSAGES.AUTHGUARDMSG'));
      this.router.navigate(['logout']);
      // this.router.navigate(['verifyidentity'], { queryParams: { 'q': localStorage.getItem('iShare_queryString') } });
    }
  }

}
