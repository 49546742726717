import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appPreventFirstCharAsSpace]'
})
export class PreventFirstCharAsSpaceDirective {
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    // Get the current value and cursor position
    const inputValue: string = (event.target as HTMLInputElement).value;
    const cursorPosition: number = (event.target as HTMLInputElement).selectionStart;

    // Check if the pressed key is space and it's the first character
    if (event.key === ' ' && cursorPosition === 0) {
      event.preventDefault(); // Prevent input of the space character
    }
  }
}
