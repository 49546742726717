import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConfigService } from 'src/app/app-config.service';
import { GeneratePinRequestModel } from 'src/app/models/generatePinRequestModel';
import { VerifyPinModel } from 'src/app/models/verifyPinModel';
import { IShareLoginService } from 'src/app/services/ishare-login.service';
import { IshareErrorhandlesService } from 'src/app/services/ishare-errorhandles.service';
import { Subject } from 'rxjs';
import { TranslateAppService } from 'src/app/services/translate-app.service';
import { Helper } from 'src/app/models/helper';


@Component({
  selector: 'app-ishare-login',
  templateUrl: './ishare-login.component.html',
  styleUrls: ['./ishare-login.component.scss']
})
export class IShareLoginComponent implements OnInit {

  loginForm: FormGroup;
  userInfoObj: GeneratePinRequestModel;
  showOTP: boolean;
  generatePinResponse: any;
  verifyOTPObj: VerifyPinModel;
  verifyId: any;
  validURL: boolean;
  emailOrPhonePresent: number;
  helperRef = Helper;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private appConfigService: AppConfigService,
    private _iShareAuthService: IShareLoginService,
    private _iShareErrorService: IshareErrorhandlesService,
    private route: ActivatedRoute,
    private _appTranslateService: TranslateAppService
  ) {

    route.queryParams.subscribe(value => {
      this.verifyId = value.q;
    });

  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.pattern('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')]],
      phone: [''],
    });
    if (this.verifyId == undefined || this.verifyId == null || this.verifyId.trim() === '') {
      setTimeout(() => {
        this.toastr.error(this._appTranslateService.getTranslattedTextFromJSONFile('VALIDATIONMESSAGES.ERRORMESSAGE1'));
      });
    }
    if (this._iShareAuthService.getQueryString(this.verifyId) == '' || this._iShareAuthService.getQueryString(this.verifyId) == null || this._iShareAuthService.getQueryString(this.verifyId) == undefined) {
      if (this.verifyId != null || this.verifyId != undefined) {
        this._iShareAuthService.updateQueryString(this.verifyId);
      }
    }
  }


  resetData() {
    this.loginForm.patchValue({
      email: '',
      phone: ''
    });
    //enable the use input
    this.loginForm.get('phone').enable();
    this.loginForm.get('email').enable();
  }

  getOTP() {
    // this.router.navigate(['otp'], { queryParams: { q: localStorage.getItem('iShare_queryString') } });
    // //disable the use input
    this.loginForm.get('phone').disable();
    this.loginForm.get('email').disable();

    this.userInfoObj = {
      email: this.loginForm.get('email').value,
      phone: this.loginForm.get('phone').value,
      queryString: this.verifyId
    }
    localStorage.setItem('email', JSON.stringify(this.userInfoObj.email));
    localStorage.setItem('phone', JSON.stringify(this.userInfoObj.phone));

    this.spinner.show();
    this._iShareAuthService.generatePin(this.userInfoObj).subscribe(data => {
      if (data.success) {
        this.generatePinResponse = data.result;
        this.spinner.hide();
        this.router.navigate(['otp'], { queryParams: { q: this._iShareAuthService.getQueryString(this.verifyId) } });
      } else {
        this.loginForm.get('phone').enable();
        this.loginForm.get('email').enable();
        this.toastr.error(data.errors[0]);
        this.spinner.hide();
      }

    }, err => {
      this.spinner.hide();
    })
  }

  loginWithPwd() {
    this.router.navigate(['login']);
  }
}
