import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private appConfig: Configs;
  private httpClient: HttpClient;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  public get APIDomain(): string {
    return this.appConfig.APIDomain;
  }
  public get APIBaseURL(): string {
    return this.appConfig.APIBaseURL;
  }
  public get APIBaseURLV1(): string {
    return this.appConfig.APIBaseURLV1;
  }
  public get production(): string {
    return this.appConfig.production;
  }
  public get EnableArabicTranslation(): string {
    return JSON.parse(this.appConfig.EnableArabicTranslation);
  }


  public async loadAppConfig(): Promise<any> {
    return this.httpClient.get('assets/appConfig.json').pipe(settings => settings)
      .toPromise()
      .then(settings => {
        this.appConfig = settings as Configs;
      });
  }
}


export interface Configs {

  APIDomain: string;
  APIBaseURL: string;
  APIBaseURLV1: string;
  clientId: string;
  production: string;
  IdentityAuthority: string;
  IdentityClientId: string;
  IdentityRedirectUri: string;
  IdentityPostLogoutRedirectUri: string;
  InstrumentationKey: string;
  EnableArabicTranslation: string;
}
