import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AzureAppInsightService } from './services/azure-app-insight.service';
import { TranslateAppService } from './services/translate-app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'IShareWeb';

  constructor(private azureAppInsight: AzureAppInsightService, private _TranslateAppService: TranslateAppService, locationPlatform: PlatformLocation) {
    //Detect browser back click
    locationPlatform.onPopState(() => {
      let currentRoute: string = location.pathname;
      if (currentRoute.includes('/verifyidentity') || currentRoute.includes('/otp')) {
        localStorage.removeItem('email');
        localStorage.removeItem('phone');
      }
    });
  }
  ngOnInit(): void {

  }
}
