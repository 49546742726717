import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from '../app-config.service';
import { Router } from '@angular/router';
import { ApiResponse } from '../models/apiResponse';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IshareDashboardService {

  constructor(private router: Router, private appConfigService: AppConfigService, private httpClient: HttpClient) { }

  getDashboardData(field, value) {
    return this.httpClient.get<ApiResponse<any>>(this.appConfigService.APIBaseURLV1 + 'IShare/GetDashboard?' + field + '=' + value);
  }
  uploadDoc(uploaddata) {

    // let formData = new FormData();
    // formData.append('file', file);
    // return this.httpClient.post<ApiResponse<any>>(this.appConfigService.APIBaseURLV1 + "Process/file", formData)
    let formData = new FormData();
    formData.append('document', uploaddata.document);
    formData.append('customerDocumentGuid', uploaddata.customerDocumentGuid);
    return this.httpClient.post<ApiResponse<any>>(this.appConfigService.APIBaseURLV1 + "IShare/UploadDocument", formData);
  }

  viewDoc() {

  }
  getDocId(verificationGuid) {
    return this.httpClient.get<ApiResponse<any>>(this.appConfigService.APIBaseURLV1 + 'IShare/GetDocumentId?verificationGuid=' + verificationGuid);
  }

  loadDoc(customerDocumentGuid, documentId) {
    // tslint:disable-next-line: max-line-length
    return this.httpClient.get(this.appConfigService.APIBaseURLV1 + 'IShare/LoadDocument?customerDocumentGuid=' + customerDocumentGuid + '&documentId=' + documentId, {
      responseType: 'blob'
    });
  }

  updateCustomerName(firstName, lastName) {
    localStorage.setItem('Customer_Name', firstName + '  ' + lastName);
  }

  getCustomerSignatureLocation(customerDocumentGuid, documentId) {
    return this.httpClient.get<ApiResponse<any>>(this.appConfigService.APIBaseURLV1 + 'IShare/GetCustomerSignatureLocation?customerDocumentGuid=' + customerDocumentGuid + '&documentId=' + documentId);
  }

  sendSignDocument(customerDocumentGuid, obj) {
    return this.httpClient.post<ApiResponse<any>>(this.appConfigService.APIBaseURLV1 + 'IShare/SendSignedDocument?customerDocumentGuid=' + customerDocumentGuid, obj);
  }

  processComplete(obj) {
    return this.httpClient.post<ApiResponse<any>>(this.appConfigService.APIBaseURLV1 + 'IShare/CompleteProcess?customerDocumentGuid=' + obj.customerDocumentGuid, obj);
  }
  customerLogAction(obj) {
    return this.httpClient.post<ApiResponse<any>>(this.appConfigService.APIBaseURLV1 + "IShare/LogCustomerAction", obj);
  }
}
