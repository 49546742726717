import { Component, Input, Host } from '@angular/core';
import { CheckboxGroupComponent } from './checkbox-group.component';

@Component({
    selector: 'checkbox',
    template: `<div class="custom-control custom-checkbox" (click)="toggleCheck()">
    <input type="checkbox" class="custom-control-input" [checked]="isChecked()">
    <label class="custom-control-label">{{label}}</label>
  </div>`,
    styles: ['.custom-control-label  { line-height: 1.4;  }']
})
export class CheckboxComponent {
    @Input() value: any;
    @Input() label: any;
    constructor(@Host() private checkboxGroup: CheckboxGroupComponent) {
    }

    toggleCheck() {
        this.checkboxGroup.addOrRemove(this.value);
    }

    isChecked() {
        return this.checkboxGroup.contains(this.value);
    }

}
