import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from 'src/app/app-config.service';
import { Enums } from 'src/app/models/Enums';
import { TranslateAppService } from 'src/app/services/translate-app.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  greeting: string;
  customerName: string;
  supportedLanguagesArr = Enums.supportedLanguages;
  changeLanguage: FormControl = new FormControl('en');
  constructor(private router: Router, private toastr: ToastrService, private _appTranslatService: TranslateAppService,
    public _appConfigService: AppConfigService) {
    this.changeLanguage.patchValue(this._appTranslatService.getSelectedLanguage());
  }

  ngOnInit() {

  }

  logout() {
    Object.keys(localStorage).forEach(element => {
      if (!element.includes('defaultLanguage')) {
        localStorage.removeItem(element);
      }
    });
    this.router.navigate(['logout']);
  }

  switchLanguage() {
    this._appTranslatService.setUserLanguage(this.changeLanguage.value);
    this.reloadPageOnLanguageChange();
  }

  reloadPageOnLanguageChange() {
    window.location.reload(); //reload the browser on language change.
  }

}
