import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-floating-button',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.scss']
})
export class FloatingButtonComponent {

  @Input('isEnable') isEnable: boolean = true;
  @Input('scrollToDivName') scrollToDivName: string = '';
  // Scroll to bottom functionality
  scrollToBottom() {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }

  // Show/hide button based on scroll position
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const yOffset = window.scrollY;
    const maxOffset = document.body.scrollHeight - window.innerHeight;
    const showOffset = 200; // Adjust this value according to your preference
    this.showButton = yOffset < maxOffset - showOffset;
  }

  showButton = false; // Initially hide the button

  scrollToSpecificDiv() {
    if (this.scrollToDivName && this.scrollToDivName.trim() !== '') {
      const element = document.getElementById(this.scrollToDivName.trim());
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

}
