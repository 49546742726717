export enum IShareResponses {
    OK = 200,
    BadRequest = 400,
    Unauthorized = 401,
    NotFound = 404,
    Conflict = 409,
    InternalServerError = 500,
    MultipleUser = 601,
    InvalidPassword = 602,
    AccountLocked = 603,
    PasswordExpired = 604,
    NewPasswordConfirmPassword = 700,
    OldPasswordNotMatch = 701,
    OldPasswordSameAsNewPassword = 702,
    FileIsPasswordProtected = 1001,
    ShareDocsNotEnabled = 922
}
