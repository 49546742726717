import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from 'src/app/app-config.service';
import { IShareLoginService } from 'src/app/services/ishare-login.service';

@Component({
  selector: 'app-ishare-forgetpassword',
  templateUrl: './ishare-forgetpassword.component.html',
  styleUrls: ['./ishare-forgetpassword.component.scss']
})
export class IshareForgetpasswordComponent implements OnInit {

  pwdForm: FormGroup = this.fb.group([]);
  resetConfirmationMessage = false;
  loader: boolean = false;
  constructor(
    private fb: FormBuilder,
    private _ishareLoginService: IShareLoginService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    private appConfigService: AppConfigService
  ) { }

  ngOnInit() {
    this.pwdForm = this.fb.group({
      clientId: [''],
      username: ['', Validators.required],
      queryString: [this._ishareLoginService.getQueryString()]
    });
  }

  ForgotPassword() {
    this.loader = true;
  
    this._ishareLoginService.forgotPassword(this.pwdForm.value).subscribe((authResult: any) => {
      this.loader = false;
      if (authResult.success) {
        this.resetConfirmationMessage = true;
      }
    }, (error) => {
      this.loader = false;
    });
  }

}
