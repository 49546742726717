import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { IShareLoginComponent } from './pages/ishare-login/ishare-login.component';
import { IshareDashboardComponent } from './pages/ishare-dashboard/ishare-dashboard.component';
import { AuthGuard } from './auth-guard/auth.guard';
import { IshareViewdocumentComponent } from './pages/ishare-viewdocument/ishare-viewdocument.component';
import { IshareSigndocumentComponent } from './pages/ishare-signdocument/ishare-signdocument.component';
import { IshareLogoutComponent } from './pages/ishare-logout/ishare-logout.component';
import { IshareSignupComponent } from './pages/ishare-signup/ishare-signup.component';
import { IshareResetpasswordComponent } from './pages/ishare-resetpassword/ishare-resetpassword.component';
import { IshareLoginpwdComponent } from './pages/ishare-loginpwd/ishare-loginpwd.component';
import { IshareForgetpasswordComponent } from './pages/ishare-forgetpassword/ishare-forgetpassword.component';
import { IshareVerifyOtpComponent } from './pages/ishare-verify-otp/ishare-verify-otp.component';


const routes: Routes = [
  {
    path: 'verifyidentity',
    component: IShareLoginComponent
  },
  {
    path: 'verifyidentity/?q=',
    component: IShareLoginComponent
  },
  {
    path: 'otp',
    // path: 'otp/:id',
    component: IshareVerifyOtpComponent
  },
  {
    path: 'otp/?q=',
    // path: 'otp/:id',
    component: IshareVerifyOtpComponent
  },
  {
    path: 'dashboard/:id',
    canActivate: [AuthGuard],
    component: IshareDashboardComponent
  },
  {
    path: 'signdoc/:id/:type/:docId',
    canActivate: [AuthGuard],
    component: IshareSigndocumentComponent
  },
  {
    path: 'signup/:id',
    canActivate: [AuthGuard],
    component: IshareSignupComponent
  },
  // {
  //   path: 'resetpassword',
  //   component: IshareResetpasswordComponent
  // },
  // {
  //   path: 'forgetpassword',
  //   component: IshareForgetpasswordComponent
  // },
  // {
  //   path: 'login',
  //   component: IshareLoginpwdComponent
  // },
  {
    path: 'logout',
    component: IshareLogoutComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
