import { Component, OnInit, forwardRef, AfterViewInit, ViewChild, HostListener, ElementRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

@Component({
  selector: 'app-signature-field',
  templateUrl: './signature-field.component.html',
  styleUrls: ['./signature-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignatureFieldComponent),
      multi: true,
    },
  ]
})
export class SignatureFieldComponent implements ControlValueAccessor, AfterViewInit {

  @ViewChild('signaturePad') public signaturePad: SignaturePad;
  @ViewChild('signatureContainer') public signatureContainer: ElementRef<HTMLElement>;

  public options: Object = {};

  public _signature: any = null;

  public propagateChange: Function = null;

  get signature(): any {
    return this._signature;
  }

  set signature(value: any) {
    this._signature = value;
    this.propagateChange(this.signature);
  }

  public writeValue(value: any): void {
    if (!value) {
      return;
    }
    this._signature = value;
    setTimeout(() => {
      this.signaturePad.fromDataURL(this.signature);
    });
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {
    // no-op
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.signaturePad.set('canvasWidth', this.signatureContainer.nativeElement.clientWidth)
      this.signaturePad.set('backgroundColor', 'rgba( 0, 0, 0,0 )');
      this.signaturePad.clear();
    });
  }

  public drawBegin(): void {

  }

  public drawComplete(): void {
    this.signature = this.signaturePad.toDataURL(null, 1);
  }

  public clear(): void {
    this.signaturePad.clear();
    this.signature = '';
  }

  @HostListener('window:resize', ['$event'])
  public setCanvasWidth(e?) {
    //Remove the previous changes based on Bug found from Regression Bugs: Disappearing the Sign/Erasing Sign Automatically on iPhone 8/7
    //Regression Test of Service Docs Share Docs v2022.1.4.1  2022 DB v2.0.44 (Iteration 27)
    if (this.signature) {
      this.signaturePad.fromData(this.signaturePad.toData());
    }
  }

}
