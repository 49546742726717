import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IshareErrorhandlesService {

  constructor(
    private toast: ToastrService
  ) { }

  handleError(error?: any) {
    console.error("Global Error Handler :", error);
    switch (error) {
      case 0:
        this.toast.error("Please check your network connection!");
        break;
      case 404:
        this.toast.error("URL not found!");
        break;
      case 500:
        this.toast.error("Server error! Please try again later!");
        break;
      case 400:
        this.toast.error("Bad request, Please try with valid input!");
        break;
      default:
        this.toast.error("Something went wrong, Please try again later");
    }

    return throwError(error);
  }

}
