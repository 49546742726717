import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { AppConfigService } from './app-config.service';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IShareLoginComponent } from './pages/ishare-login/ishare-login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GlobalHttpInterceptor } from './interceptors/http-interceptor';
import { JWT_OPTIONS, JwtInterceptor, JwtModule, JwtHelperService } from '@auth0/angular-jwt';
import { CommonModule, DatePipe } from '@angular/common';
import { IshareDashboardComponent } from './pages/ishare-dashboard/ishare-dashboard.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { IshareDashboardService } from './services/ishare-dashboard.service';
import { IShareLoginService } from './services/ishare-login.service';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IshareViewdocumentComponent } from './pages/ishare-viewdocument/ishare-viewdocument.component';
import { PdfJsViewerModule } from 'src/assets/lib/ng2-pdfjs-viewer';
import { IshareSigndocumentComponent } from './pages/ishare-signdocument/ishare-signdocument.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignatureFieldComponent } from './shared/components/signature-field/signature-field.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { IShareDateFormatPipe } from './shared/pipes/i-share-date-format.pipe';
import { IshareDashboardActiongridComponent } from './pages/ishare-dashboard-actiongrid/ishare-dashboard-actiongrid.component';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { CheckboxGroupComponent } from "./shared/components/checkbox-group/checkbox-group.component";
import { CheckboxComponent } from "./shared/components/checkbox-group/checkbox.component";
import { IshareLogoutComponent } from './pages/ishare-logout/ishare-logout.component';
import { IshareSignupComponent } from './pages/ishare-signup/ishare-signup.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { IshareResetpasswordComponent } from './pages/ishare-resetpassword/ishare-resetpassword.component';
import { IshareLoginpwdComponent } from './pages/ishare-loginpwd/ishare-loginpwd.component';
import { IshareForgetpasswordComponent } from './pages/ishare-forgetpassword/ishare-forgetpassword.component';
import { AzureErrorHandlerService } from './services/azure-error-handler.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IshareVerifyOtpComponent } from './pages/ishare-verify-otp/ishare-verify-otp.component';
import { FloatingButtonComponent } from './shared/components/floating-button/floating-button.component';
import { PreventFirstCharAsSpaceDirective } from './shared/directives/prevent-first-char-as-space.directive';


export function jwtOptionsFactory(ishareAuthService: IShareLoginService, appConfigService: AppConfigService) {
  return {
    tokenGetter: () => {
      return ishareAuthService.getAuthToken();
    },
    whitelistedDomains: [appConfigService.APIDomain]
  };
}

export function appInitializerFactory(appConfig: AppConfigService) {
  return () => appConfig.loadAppConfig();
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?q=' + Date.now());
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    IShareLoginComponent,
    IshareDashboardComponent,
    HeaderComponent,
    FooterComponent,
    IshareViewdocumentComponent,
    IshareSigndocumentComponent,
    SignatureFieldComponent,
    IShareDateFormatPipe,
    IshareDashboardActiongridComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    IshareLogoutComponent,
    IshareSignupComponent,
    IshareResetpasswordComponent,
    IshareLoginpwdComponent,
    IshareForgetpasswordComponent,
    IshareVerifyOtpComponent,
    FloatingButtonComponent,
    PreventFirstCharAsSpaceDirective
  ],
  imports: [
    CommonModule,
    BrowserModule,
    PdfJsViewerModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true,
      autoDismiss: false
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [IShareLoginService, AppConfigService]
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    InputsModule,
    SignaturePadModule,
    GridModule,
    DatePickerModule,
    DialogsModule,
    TranslateModule,
    DropDownsModule
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true,
      deps: [AppConfigService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
    useClass: AzureErrorHandlerService
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
