import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateAppService {

  public _translate: TranslateService;
  constructor(translate: TranslateService) {
    this._translate = translate;
    this.setDefaultApplicationLanguage(translate);
  }

  setDefaultApplicationLanguage(translate: TranslateService) {
    let language = localStorage.getItem('defaultLanguage');
    if (!language) {
      language = 'en';
      localStorage.setItem('defaultLanguage', 'en');
    }
    translate.setDefaultLang(language);
    translate.use(language);
  }

  getSelectedLanguage() {
    if (localStorage.getItem('defaultLanguage') != null) {
      return localStorage.getItem('defaultLanguage');
    } else {
      return "en";
    }
  }

  setUserLanguage(language) {
    localStorage.setItem('defaultLanguage', language);
    this._translate.setDefaultLang(language);
    this._translate.use(language);
  }


  public getTranslattedTextFromJSONFile(statusCode: any): string {
    let currentUserLanguage: string = '';
    let translattedText: string = '';
    if (localStorage.getItem('defaultLanguage')) {
      currentUserLanguage = localStorage.getItem('defaultLanguage');
    } else {
      currentUserLanguage = 'en';
    }
    this._translate.setDefaultLang(currentUserLanguage);
    this._translate.use(currentUserLanguage);
    this._translate.get(statusCode).subscribe((translatedObject) => {
      translattedText = translatedObject;
      return translattedText;
    });
    return translattedText;
  }
}
