import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { IshareDashboardService } from 'src/app/services/ishare-dashboard.service';
import { PdfJsViewerComponent } from 'src/assets/lib/ng2-pdfjs-viewer';

@Component({
  selector: 'app-ishare-viewdocument',
  templateUrl: './ishare-viewdocument.component.html',
  styleUrls: ['./ishare-viewdocument.component.scss']
})
export class IshareViewdocumentComponent implements OnInit {

  loader: boolean;
  customerDocumentGuid: string;
  @ViewChild('pdfViewer') pdfViewer: PdfJsViewerComponent;
  @Input() customerDocumentguid: any;
  @Input() documentId: any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private _dashboardService: IshareDashboardService
  ) {
    route.params.subscribe(value => {
      this.customerDocumentGuid = value.id;

    });
  }

  ngOnInit() {
    this.viewDoc();
  }

  viewDoc() {
    this.loader = true;
    this.pdfViewer.showSpinner = true;
    this._dashboardService.loadDoc(this.customerDocumentGuid, this.documentId).subscribe(data => {
      this.pdfViewer.pdfSrc = new Blob([data], { type: data.type });
      this.pdfViewer.showSpinner = false;
      this.pdfViewer.refresh();
      this.loader = false;
    }, err => {
      this.loader = false;
    });
  }
  // signDoc() {
  //   this.router.navigate(['/signdoc', this.customerDocumentGuid]);
  // }
}
