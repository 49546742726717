import { Enums } from "./Enums";

export class Helper {

   public static getMonthNumber(month) {
        switch (month) {
            case "Jan":
                return 1;
            case "Feb":
                return 2;
            case "Mar":
                return 3;
            case "Apr":
                return 4;
            case "May":
                return 5;
            case "Jun":
                return 6;
            case "Jul":
                return 7;
            case "Aug":
                return 8;
            case "Sep":
                return 9;
            case "Oct":
                return 10;
            case "Nov":
                return 11;
            case "Dec":
                return 12;

            default:
                return 0;
        }
    }

  public static getDateFromDateString(date: any) {
    //date format must be like this ex. 22/Sep/2020
    let dateString = date;
    return dateString.substr(0, 2);
  }

  public static getMonthNumberFromDateString(date: any) {
    let dateString = date;
    let month = dateString.substr(3, 3);
    let monthNumber = this.getMonthNumber(month);
    return monthNumber - 1;
  }

  public static getYearFromDateString(date: any) {
    let dateString = date;
    return dateString.substr(7, 4);
  }

  public static validateFileType(files: File) {
    let allowedExtensions = Enums.allowFileUploadExtension;
    let extension = files.name.substr(files.name.lastIndexOf("."), files.name.length).toLowerCase();
    if (!Enums.allowFileUploadExtension.includes(extension)) {
      return false;
    }
    return true;
  }
  public static maskEmailAddress(email: string, maskSign: string): string {
    var maskid = "";
    var myemailId = email;
    var prefix = myemailId.substring(0, myemailId.lastIndexOf("@"));
    var postfix = myemailId.substring(myemailId.lastIndexOf("@"));

    for (var i = 0; i < prefix.length; i++) {
      if (i == 0 || i == prefix.length - 1) {
        maskid = maskid + prefix[i].toString();
      }
      else {
        maskid = maskid + maskSign;
      }
    }
    maskid = maskid + postfix;
    return maskid;
  }

  public static maskPhoneNumber(phoneno: string, maskSign: string): string {
    return phoneno.replace(/\d(?=.*\d{4})/g, maskSign);
  }

  public static isNumber(evt, allowDecimal: boolean): boolean {
    if (allowDecimal) {
      return !isNaN(evt.key) || evt.keyCode == 8 || evt.keyCode == 9 || evt.keyCode == 190 || evt.keyCode == 110;
    } else {
      return !isNaN(evt.key) || evt.keyCode == 8 || evt.keyCode == 9;
    }
  }

}
