import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConfigService } from 'src/app/app-config.service';
import { IshareDashboardService } from 'src/app/services/ishare-dashboard.service';
import { combineLatest } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { IShareLoginService } from 'src/app/services/ishare-login.service';
import { GridDataResult, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { HttpResponse } from '@angular/common/http';
import { Helper } from 'src/app/models/helper';
import { PdfJsViewerComponent } from 'src/assets/lib/ng2-pdfjs-viewer';
import { Enums } from 'src/app/models/Enums';
import { TranslateAppService } from 'src/app/services/translate-app.service';

@Component({
  selector: 'app-ishare-dashboard',
  templateUrl: './ishare-dashboard.component.html',
  styleUrls: ['./ishare-dashboard.component.scss']
})
export class IshareDashboardComponent implements OnInit {

  greeting: string;
  verifyIdString: any;
  dashboardData: any;
  docIdData: any;
  loader: boolean;
  emailOrPhonePresent: any;
  fieldString: string;
  fieldValue: string;
  showNotificationBanner: any;
  customerName: string;
  public dashboardGridData: GridDataResult;
  public dashboardGridLoader: boolean;
  public state: State = {


  };
  public expandedRows: any[] = [];
  showRegisteruserDialog: boolean;
  tmpfile: any;
  @ViewChild('pdfviewer') pdfviewer: PdfJsViewerComponent;
  @ViewChild('dashboardGrid') dashboardGrid: GridComponent;
  customerDocumentguid: any;
  hrs:number = 0;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private appConfigService: AppConfigService,
    private route: ActivatedRoute,
    private _dashboardService: IshareDashboardService,
    private sanitizer: DomSanitizer,
    private _iShareLoginService: IShareLoginService,
    private _appTranslateService: TranslateAppService

  ) {
    route.params.subscribe(value => {
      this.verifyIdString = value.id;
      this.emailOrPhonePresent = value.id;

    });

  }

  setupGreetingMessage() {
    const myDate = new Date();
    this.hrs = myDate.getHours();
  }

  ngOnInit() {
    this.setupGreetingMessage();
    this.getDashboardData();
  }


  public dataStateChange(state: State): void {
    this.state = <DataStateChangeEvent>state;
    this.dashboardGridData = process(this.dashboardData.filePacks, this.state);
  }

  uploadFile(file, customerDocumentGuid) {
    if (file.files[0] && file.files[0].size > 1000) {
      if (file.files[0] && Helper.validateFileType(file.files[0])) {
        let obj = {
          customerDocumentGuid: customerDocumentGuid,
          document: file.files[0]
        }
        this.loader = true;
        this._dashboardService.uploadDoc(obj).subscribe(data => {
          if (data.success) {
            this.toastr.success(this._appTranslateService.getTranslattedTextFromJSONFile('VALIDATIONMESSAGES.UPLOADMSG'));
            this.loader = false;
            this.getDashboardData();
            this.removeExpandedRows();
          } else {
            this.loader = false;
            this.getDashboardData();
            this.removeExpandedRows();
          }
        }, err => {
          this.loader = false;
          this.getDashboardData();
        })
      }
      else {
        this.toastr.error(this._appTranslateService.getTranslattedTextFromJSONFile('VALIDATIONMESSAGES.FILEEXTMSG') + Enums.allowFileUploadExtension.toString());
      }
    } else {
      this.toastr.error(this._appTranslateService.getTranslattedTextFromJSONFile('VALIDATIONMESSAGES.FILESIZEMESSAGE'));
    }
  }

  viewSignDoc(customerDocumentGuid, documentId) {
    this.router.navigate(['/signdoc', customerDocumentGuid, this.emailOrPhonePresent, documentId]);
  }

  downloadDoc(dataItem) {
    this.loader = true;
    this.pdfviewer.showSpinner = true;
    this._dashboardService.loadDoc(dataItem.customerDocumentGuid, dataItem.documentId).subscribe((data) => {
      let fileName = dataItem.rawData.iShareName != null || dataItem.rawData.iShareName != '' ? dataItem.rawData.iShareName : dataItem.rawData.documentTypeName;

      this.pdfviewer.pdfSrc = new Blob([data], { type: data.type });
      this.pdfviewer.downloadFileName = fileName;
      this.pdfviewer.viewerId = dataItem.customerDocumentGuid;
      this.pdfviewer.showSpinner = false;
      this.pdfviewer.refresh();
      this.customerDocumentguid = dataItem.customerDocumentGuid;
      this.removeExpandedRows();
    }, err => {
      this.loader = false;
      this.getDashboardData();
    })
  }

  public pdfPagesLoaded(evt: number) {
    this.loader = true;
    if (evt != 0) {
      this.loader = false;
      this.toastr.success(this._appTranslateService.getTranslattedTextFromJSONFile('VALIDATIONMESSAGES.DOWNLOADMSG'));
      this.customerLogAction(this.customerDocumentguid, "ShareDocsDocDownloaded");
      this.processComplete(this.customerDocumentguid);
    }
  }

  getDashboardData() {
    if (this.emailOrPhonePresent == 1) {
      this.fieldString = "email";
      this.fieldValue = this._iShareLoginService.getEmailAddress();
    } else if (this.emailOrPhonePresent == 0) {
      this.fieldString = "phone";
      this.fieldValue = this._iShareLoginService.getPhoneNo();
    }
    else {
      this.fieldString = 'email';
      this.fieldValue = '';
    }
    this.dashboardGridLoader = true;
    this._dashboardService.getDashboardData(this.fieldString, this.fieldValue).subscribe(data => {
      if (data.success) {
        this.dashboardGridLoader = false;
        this.dashboardData = data.result;
        this.showNotificationBanner = false;
        this.customerName = ", " + data.result.firstName + " " + data.result.lastName
        this._dashboardService.updateCustomerName(data.result.firstName, data.result.lastName);
        this.dashboardData.filePacks.forEach(element => {
          element.showStatus = true;
          // element.showNotificationBanner = true;
          if (element.documents.length > 0) {
            element.documents.forEach(documntselement => {
              if (!documntselement.isProcessComplete) {
                element.showStatus = false;
                // element.showNotificationBanner = false;
              }
              if (!documntselement.isProcessComplete) {
                this.showNotificationBanner = true;
                this.expandedRows.push(element.filePackId);
                this.expandedRows = Array.from(new Set(this.expandedRows));
              }
              if (documntselement.isUploadRequest == false && documntselement.isDownloadRequest == false) {
                documntselement.isSignatureRequest = true;
              } else {
                documntselement.isSignatureRequest = false;
              }
            });
          }
          this.dataStateChange(this.state);
        });
        // this.dashboardData.isCustomerRegistered = true // debug
        // if (!this.dashboardData.isCustomerRegistered) {
        //   this.showRegisteruserDialog = true;

        // }
      } else {
        this.dashboardGridLoader = false;
      }
    }, err => {
      this.dashboardGridLoader = false;
    })

  }

  public sendRegisterUserReq() {
    this.router.navigate(['signup', this.dashboardData.customerPortalUserGuid]);
  }

  showmsg(msg: string) {
    this.toastr.success(msg);
  }

  processComplete(customerDocumentGuid) {
    let obj = {
      customerDocumentGuid: customerDocumentGuid
    }
    this._dashboardService.processComplete(obj).subscribe(data => {
      this.getDashboardData();
    }, err => {
      this.getDashboardData();
    });
  }

  logout() {
    localStorage.removeItem('IShare_accessToken');
  }
  isharePerformActions(data) {
    if (data.field == "Sign") {
      this.viewSignDoc(data.customerDocumentGuid, data.documentId);
    } else if (data.field == "Download") {
      this.downloadDoc(data);

    } else if (data.field == "Upload") {
      this.uploadFile(data.file, data.customerDocumentGuid);
    }
  }

  public showExpandDetails = (dataItem: any): any => {
    return dataItem.filePackId;
  }

  customerLogAction(customerDocumentGuid, type) {
    let obj = {
      type: type,
      customerDocumentGuid: customerDocumentGuid
    }
    this._dashboardService.customerLogAction(obj).subscribe(data => {

    }, err => {

    });
  }

  removeExpandedRows() {
    this.dashboardData.filePacks.forEach(element => {
      if (element.documents.length > 0) {

        element.documents.forEach(documentselement => {
          if (documentselement.isProcessComplete) {
            let docPresent = this.expandedRows.includes(element.filePackId);
            if (docPresent) {
              let idx = this.expandedRows.findIndex(x => element.filePackId);
              this.expandedRows.splice(idx, 1);
            }
          }
        });
      }
    });
  }
}
