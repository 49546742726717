import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from 'src/app/app-config.service';
import { IShareLoginService } from 'src/app/services/ishare-login.service';

@Component({
  selector: 'app-ishare-resetpassword',
  templateUrl: './ishare-resetpassword.component.html',
  styleUrls: ['./ishare-resetpassword.component.scss']
})
export class IshareResetpasswordComponent implements OnInit {

  resetpwdForm: FormGroup = this.fb.group([]);
  resetConfirmationMessage = false;
  loader: boolean = false;
  constructor(private fb: FormBuilder,
    private _ishareLoginService: IShareLoginService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    private appConfigService: AppConfigService
  ) { }

  ngOnInit() {
    this.resetpwdForm = this.fb.group({
      clientId: [''],
      username: ['', Validators.required],
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      confirmPassword: ['', Validators.required],
      queryString: [this._ishareLoginService.getQueryString()]
    });
  }


  ResetPassword() {

    this.loader = true;
    let resetpasswordInfo = {
      ...this.resetpwdForm.value
    }
    this._ishareLoginService.resetPassword(resetpasswordInfo).subscribe((authResult: any) => {
      this.loader = false;
      if (authResult.success) {
        this.toastr.success("Your Password changed successfully.")
        this.resetConfirmationMessage = true;
        this.router.navigate(['login'])
        // this.router.navigate(['verifyidentity'], { queryParams: { 'q': localStorage.getItem('iShare_queryString') } });
      }
    }, (error) => {
      this.loader = false;
    });
  }

}
