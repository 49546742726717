import { HttpRequest, HttpHandler, HttpInterceptor } from "@angular/common/http";
import { Observable, BehaviorSubject, throwError } from "rxjs";
import { mergeMap, catchError, filter, take, switchMap, map } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Injector, ErrorHandler, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { IshareApiResponse } from '../models/IshareApiResponse';
import { IShareResponses } from '../models/IshareResponseEnum';
import { ApiResponse } from '../models/apiResponse';
import { AppConfigService } from "../app-config.service";


@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {
  constructor(
    private toast: ToastrService,
    private router: Router,
    private _appConfigService: AppConfigService
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<import("@angular/common/http").HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        "Permissions-Policy": "camera=*,geolocation=*,microphone=*,autoplay=*,fullscreen=*,picture-in-picture=*,sync-xhr=*,encrypted-media=*,oversized-images=*",
        "Strict-Transport-Security": "max-age=31536000; includeSubdomains",
        "X-Frame-Options": "SAMEORIGIN",
        "X-Content-Type-Options": "nosniff",
        "X-Xss-Protection": "1; mode=block",
        "Content-Security-Policy": "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data: blob:;font-src https: data:;",
        "Accept-Language": this.getLanguageHeader()
      }
    });
    return next.handle(req).pipe(
      map((res: any) => {
        if (res.ok && res != null) {
          if (res.body && !res.body.success && 'statusCode' in res.body) {
            if (this._appConfigService.EnableArabicTranslation) {
              this.handleCustomErrorForV5(res.body);
            } else {
              this.handleCustomErrorForV4(res.body);
            }
          }
        }
        return res;
      }),
      catchError(err => {
        return this.handleError(err, req, next);
      }),

    );
  }

  handleCustomErrorForV4(res: ApiResponse<any>) {
    switch (res.statusCode) {
      case IShareResponses.PasswordExpired:
        this.toast.error("Your password has been expired, please set a new password.")
        break;
      case IShareResponses.NewPasswordConfirmPassword:
        this.toast.error("NewPassword and confirm password should be same.");
        break;
      case IShareResponses.OldPasswordNotMatch:
        this.toast.error("Old password does not match.");
        break;
      case IShareResponses.OldPasswordSameAsNewPassword:
        this.toast.error("New password must be different to old password.");
        break;
      case IShareResponses.NotFound:
        this.toast.error(res.errors.join(","));
        break;
      case IShareResponses.AccountLocked:
        this.toast.error(res.errors.join(","));
        break;
      case IShareResponses.InvalidPassword:
        this.toast.error(res.errors.join(","));
        break;
      case IShareResponses.BadRequest:
        this.toast.error(res.errors.join(","));
        break;
      case IShareResponses.FileIsPasswordProtected:
        this.toast.error(res.errors.join(","));
        break;
      case IShareResponses.ShareDocsNotEnabled:
        this.toast.error(res.errors.join(","));
        break;
      default:
        this.toast.error("Something went wrong, Please try again later!");
        break;
    }
  }

  handleCustomErrorForV5(res: ApiResponse<any>) {
    if (res.statusCode) {
      this.toast.error(res.errors.join(","));
    }
    else {
      this.toast.error("Something went wrong, Please try again later!");
    }
  }
  handleError(error: any, req: HttpRequest<any>, next: HttpHandler) {
    console.error("Global Error Handler :", error);
    switch (error.status) {
      case 0:
        this.toast.error("Please check your network connection!");
        break;
      case 404:
        this.toast.error("URL not found!");
        break;
      case 500:
        this.toast.error("Server error! Please try again later!");
        break;
      case 400:
        this.toast.error("Bad request, Please try with valid input!");
        break;
      default:
        this.toast.error("Something went wrong, Please try again later");
    }

    return throwError(error);
  }

  getLanguageHeader() {
    if (localStorage.getItem('defaultLanguage') != null) {
      return localStorage.getItem('defaultLanguage');
    } else {
      return "en";
    }
  }

}
