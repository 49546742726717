import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/app-config.service';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../models/apiResponse';


@Injectable({
  providedIn: 'root'
})
export class IShareLoginService {


  public headers = new HttpHeaders({})
  // private clientId = this.appConfigService.clientId.toString();

  constructor(private router: Router, private appConfigService: AppConfigService, private httpClient: HttpClient) {

  }

  private commonHeader() {
    if (localStorage.getItem('IShare_accessToken') === null) {
      this.router.navigateByUrl('/ishare');
    } else {
      const IShareAccessToken = localStorage.getItem('IShare_accessToken');;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${IShareAccessToken}`
      });
      return headers;
    }
  }
  generatePin(data) {
    return this.httpClient.post<ApiResponse<any>>(this.appConfigService.APIBaseURLV1 + 'IShare/GeneratePin', data);
  }

  verifyePin(data) {
    return this.httpClient.post<ApiResponse<any>>(this.appConfigService.APIBaseURLV1 + 'IShare/VerifyPin', data);
  }
  getVerifyMobileRegistered(queryString) {
    return this.httpClient.post<ApiResponse<any>>(this.appConfigService.APIBaseURLV1 + 'IShare/VerifyMobileRegistered', queryString);
  }

  getAuthToken() {
    return localStorage.getItem('IShare_accessToken');
  }
  updateAuthToken(accessToken) {
    localStorage.setItem('IShare_accessToken', accessToken);
  }
  updateEmailId(emailid) {
    localStorage.setItem("iShare_customerEmail", emailid);
  }
  updatephoneNo(phoneno) {
    localStorage.setItem("iShare_customerPhoneNo", phoneno);
  }
  getEmailAddress() {
    return localStorage.getItem("iShare_customerEmail");
  }
  getPhoneNo() {
    return localStorage.getItem("iShare_customerPhoneNo");
  }

  updateQueryString(queryString) {
    return localStorage.setItem("iShare_queryString" + queryString, queryString);
  }

  getQueryString(queryString?: string) {
    return localStorage.getItem("iShare_queryString" + queryString);
  }

  registerCustomer(obj) {
    return this.httpClient.post<ApiResponse<any>>(this.appConfigService.APIBaseURLV1 + 'Auth/RegisterIshareCustomer', obj);
  }

  resetPassword(obj) {
    return this.httpClient.post<ApiResponse<any>>(this.appConfigService.APIBaseURLV1 + 'Auth/IshareResetPassword', obj);
  }
  getIShareAccessToken(obj) {
    return this.httpClient.post<ApiResponse<any>>(this.appConfigService.APIBaseURLV1 + 'Auth/GetiShareAccessToken', obj);
  }
  forgotPassword(obj) {
    return this.httpClient.post<ApiResponse<any>>(this.appConfigService.APIBaseURLV1 + 'Auth/IshareForgotPassword', obj);
  }
  getEmailOnLogin() {
    return JSON.parse(localStorage.getItem("email"));
  }
  getPhoneOnLogin() {
    return JSON.parse(localStorage.getItem("phone"));
  }
  setEmailOnLogin(email: string) {
    return localStorage.setItem('email', JSON.stringify(email));
  }
  setPhoneOnLogin(phone: string) {
    return localStorage.setItem('phone', JSON.stringify(phone));;
  }
}
