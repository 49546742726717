import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConfigService } from 'src/app/app-config.service';
import { IShareLoginService } from 'src/app/services/ishare-login.service';
import { IshareErrorhandlesService } from 'src/app/services/ishare-errorhandles.service';

@Component({
  selector: 'app-ishare-loginpwd',
  templateUrl: './ishare-loginpwd.component.html',
  styleUrls: ['./ishare-loginpwd.component.scss']
})
export class IshareLoginpwdComponent implements OnInit {


  loginForm: FormGroup;
  passwordValidations = [Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}'), Validators.required]
  constructor(

    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private appConfigService: AppConfigService,
    private _iShareAuthService: IShareLoginService,
    private _iShareErrorService: IshareErrorhandlesService,
    private _ishareLoginService: IShareLoginService,
    private route: ActivatedRoute

  ) { }

  ngOnInit() {

    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    // password: ['', this.passwordValidations]
  }

  login() {
    let obj = {
      clientId: '',
      username: this.loginForm.get("username").value,
      password: this.loginForm.get("password").value,
      productId: 0,
      queryString: this._ishareLoginService.getQueryString()
    }

    this.spinner.show();
    this._ishareLoginService.getIShareAccessToken(obj).subscribe(data => {
      this.spinner.hide();
      if (data.statusCode == 604) {
        // this.router.navigate(['resetpassword']);
      }
      else if (data.statusCode == 500) {
        this.spinner.hide();
      }
      else {
        if (data.result != null) {
          this.router.navigate(['dashboard', 2]);

          if (this._iShareAuthService.getQueryString() == '' || this._iShareAuthService.getQueryString() == null || this._iShareAuthService.getQueryString() == undefined) {
            this._iShareAuthService.updateQueryString(data.result["access_token"]);
          }
          this._ishareLoginService.updateAuthToken(data.result["access_token"]);
        }
      }

    }, err => {
      this.spinner.hide();
    })
  }

  resetForm() {
    this.loginForm.reset();
  }

}
