import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public smallScreen: boolean = false;
  constructor() {
    this.smallScreen = window.innerWidth < 520 ? true : false;
    window.onresize = (e) => {
      this.onResize(e);
    }
  }

  onResize(event) {
    this.smallScreen = event.target.screen.width < 520 ? true : false;
  }
}
