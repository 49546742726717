import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConfigService } from 'src/app/app-config.service';
import { IShareLoginService } from 'src/app/services/ishare-login.service';
import { IshareErrorhandlesService } from 'src/app/services/ishare-errorhandles.service';
import { IshareDashboardService } from 'src/app/services/ishare-dashboard.service';

@Component({
  selector: 'app-ishare-signup',
  templateUrl: './ishare-signup.component.html',
  styleUrls: ['./ishare-signup.component.scss']
})
export class IshareSignupComponent implements OnInit {

  signUpForm: FormGroup;
  passwordValidations = [Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}'), Validators.required]
  verifyId: any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private appConfigService: AppConfigService,
    private _iShareAuthService: IShareLoginService,
    private _iShareErrorService: IshareErrorhandlesService,
    private _ishareLoginService: IShareLoginService,
    private route: ActivatedRoute
  ) {


    route.params.subscribe(value => {
      this.verifyId = value.id;
    });

  }

  ngOnInit() {

    this.signUpForm = this.fb.group({
      userName: [''],
      displayName: [''],
      password: ['', this.passwordValidations]
    });

  }

  public signUp() {
    let obj = {
      customerPortalUserGuid: this.verifyId,
      userName: this.signUpForm.get("userName").value,
      displayName: this.signUpForm.get("displayName").value,
      password: this.signUpForm.get("password").value
    }

    this.spinner.show();
    this._ishareLoginService.registerCustomer(obj).subscribe(data => {
      if (data.success) {
        this.spinner.hide();
        //this.router.navigate(['verifyidentity'], { queryParams: { 'q': localStorage.getItem('iShare_queryString') } });
        this.router.navigate(['login']);
        localStorage.removeItem('IShare_accessToken');
        localStorage.removeItem('iShare_customerPhoneNo');
        localStorage.removeItem('iShare_customerEmail');
        // localStorage.removeItem('iShare_queryString');

      } else {
        this.spinner.hide();
      }
    }, err => {
      this.spinner.hide();
    })
  }

  resetData() {
    this.signUpForm.reset();
  }

}
