import { Injectable, ErrorHandler } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppConfigService } from 'src/app/app-config.service';
import { AzureAppInsightService } from './azure-app-insight.service';

@Injectable({
  providedIn: 'root'
})
export class AzureErrorHandlerService extends ErrorHandler {

  clientId: any = '';
  clientName: any = '';

  constructor(private azureAppInsightService: AzureAppInsightService) {
    super()
  }

  handleError(error: Error) {
    this.decodeValueFromAccessToken();
    this.azureAppInsightService.logException(error, {
      ClientId: this.clientId,
      ClientName: this.clientName
    }); // Manually log exception
  }

  decodeValueFromAccessToken() {
    const helper = new JwtHelperService();
    var decodedToken = helper.decodeToken(localStorage.getItem('IShare_accessToken'));
    if (!decodedToken) {
      this.clientId = '';
      this.clientName = '';
    } else {
      this.clientId = decodedToken.clientid;
      this.clientName = decodedToken.client_name;
    }

  }
}
