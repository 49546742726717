import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IshareDashboardService } from 'src/app/services/ishare-dashboard.service';
import { Question, SignatureOverlay } from 'src/app/models/signatureDocuments';
import { DocInfo } from 'src/app/models/docInfo';
import { DatePipe } from '@angular/common';
import { Helper } from 'src/app/models/helper';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-ishare-signdocument',
  templateUrl: './ishare-signdocument.component.html',
  styleUrls: ['./ishare-signdocument.component.scss']
})
export class IshareSigndocumentComponent implements OnInit {
  docId: any;
  customerDocumentGuid: any;
  loader: boolean;
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  documentData: DocInfo;
  overlayGroup: FormGroup = this.fb.group([]);
  mainLoader: boolean;
  signatureData: SignatureOverlay;
  screenWidth: number;
  overlayData: any[] = [];
  isShowOverride: boolean;
  readyForIShareFlag: boolean;
  requestType: any;
  documentId: any;
  dateFormat: string;

  constructor(
    private _iShareDashboardService: IshareDashboardService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private _datePipe: DatePipe,
    private router: Router,
    public sharedService: SharedService
  ) {
    route.params.subscribe(value => {
      this.customerDocumentGuid = value.id;
      this.requestType = value.type;
      this.documentId = value.docId;
    });


  }

  ngOnChanges() {
    if (this.signatureData) {
      if (!this.signatureData.documentStale) {
        this.overlayGroup = this.createFormGroup();
      }
    }
  }

  ngOnInit() {
    // this.documentData = this.documentType.doc;
    this.loader = true;
    this._iShareDashboardService.getCustomerSignatureLocation(this.customerDocumentGuid, this.documentId).subscribe(response => {
      this.loader = false;
      if (response.success && response.result) {
        //#region Bug 5655: Bug for Mandatory signatures

        if (response.result && response.result.questions) {
          response.result.questions.forEach((element: Question) => {

            if (response.result.productId == 0 || response.result.productId == 1) {
              //check for SalesDocs
              if (element.isSignature && element.isFloating && !element.mandatory) {
                //check if overlay is for sign overlay and if it is not mandatory then hide sign overlay for sales docs
                element.hideSignBoxForSalesDocs = true;
              } else {
                //check if overlay is not for sign overlay then do as it is work.
                element.hideSignBoxForSalesDocs = false;
              }
            } else {
              //check for ServiceDocs
              element.hideSignBoxForSalesDocs = false;
            }
          });
        }

        //#endregion
        this.signatureData = response.result;
        this.dateFormat = response.result.globalDateFormat == "" || response.result.globalDateFormat == null ? 'dd/MM/yyyy' : response.result.globalDateFormat;
        if (!this.signatureData.documentStale) {
          this.overlayGroup = this.createFormGroup();
        }
      }
    }, err => {
      this.loader = false;
    });
  }

  createFormGroup() {
    this.overlayData = [];
    this.overlayData.push({ 'title': 'Customer Details', questions: this.signatureData.questions.filter(x => x.isCustomerOverlay) });

    let obj = {};
    if (this.signatureData.isHardCodedDocument) {
      obj["hardCodedFormSignature"] = ['', Validators.required];
    }
    else {


      this.signatureData.questions.forEach(que => {
        let validators = [];
        if (que.mandatory)
          validators.push(Validators.required);
        if (que.maxLength)
          validators.push(Validators.maxLength(que.maxLength));

        let savedValue: any;

        if (que.isMultipleChoice && que.answer && que.answer != '')
          savedValue = JSON.parse(que.answer);
        else if (que.isDate && que.answer && que.answer != '') {
          //Reverted back this PR on 11-01-2022 -> https://idealfile.visualstudio.com/iDealJobFile/_git/iShareWeb/pullrequest/1647
          let dateString = que.answer;
          let date = Helper.getDateFromDateString(dateString);
          let month = Helper.getMonthNumberFromDateString(dateString);
          let year = Helper.getYearFromDateString(dateString);

          savedValue = new Date(Number(year), month, Number(date));
        } else if (que.isBoolean) {
          if (que.answer) {
            savedValue = que.answer.toLowerCase();
          }
        } else if (que.answer && que.isSignature) {
          savedValue = "";
        }

        else {
          if (que.answer) {
            savedValue = que.answer;
          }
        }
        obj["control" + que.overlayId + que.overlayTypeId] = [savedValue, validators];
      });
    }
    obj["confirmation"] = [false, this.checkedValidator];
    return this.fb.group(obj);
  }

  save(isSignDocument) {
    if (this.signatureData.isHardCodedDocument) {
      this.signatureData.hardCodedFormSignature = this.overlayGroup.get("hardCodedFormSignature").value;
    }
    else {

      this.signatureData.questions.forEach(que => {
        let value = this.overlayGroup.get('control' + que.overlayId + que.overlayTypeId).value;
        if (que.isMultipleChoice && (this.signatureData.productId == 1 || this.signatureData.productId == 0)) {
          if (Array.isArray(value)) {
            que.answer = value.length > 0 ? value[0] : null;
          } else {
            que.answer = value;
          }
        } else {
          que.answer = que.isMultipleChoice ? (value != null) ? JSON.stringify(value) : null : (que.isDate ? this._datePipe.transform(value, this.dateFormat) : value);
        }
      });
      this.signatureData.hardCodedFormSignature = '';

    }
    if (isSignDocument) {
      this.signatureData.isSignDocument = isSignDocument;

    }
    this.signatureData.signByiShare = false;
    this.mainLoader = true;
    this._iShareDashboardService.sendSignDocument(this.customerDocumentGuid, this.signatureData).subscribe(response => {
      this.mainLoader = false;
      if (response.success) {
        this.signatureData = response.result;
        if (this.signatureData && !this.signatureData.documentStale) {
          this.router.navigate(['dashboard', this.requestType]);
        }
      }
    }, err => {
      this.mainLoader = false;
    });
  }
  checkedValidator(control: AbstractControl) {
    if (control.value) {
      return true;
    }
    return {
      "confirmationError": "Please agree to the terms."
    }
  }

  checkChange(changeEvt, item, controlName) {
    if ((this.signatureData.productId == 0 || this.signatureData.productId == 1) && changeEvt.type == "add") {
      this.overlayGroup.patchValue({
        [controlName]: changeEvt.value
      })
      this.overlayGroup.updateValueAndValidity();
    }
  }

}
